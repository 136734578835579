import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container/container";
// markup
const NousJoindre = () => {
  return (
    <Layout>
      <Container>
        <h1>Nous Joindre</h1>
        <p>absdahsduas</p>
        <p>ausahduahsduhasd</p>
      </Container>
    </Layout>
  );
};

export default NousJoindre;
